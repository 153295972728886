
import {
  AnalyticsEvent,
  type Camera,
  type DateType,
  type Media,
  MediaStatus,
  MediaType,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import Vue, { type PropType } from "vue"
import MediaTypeBadge from "@evercam/shared/components/medias/MediaTypeBadge.vue"

export default Vue.extend({
  name: "MediaClip",
  components: {
    MediaTypeBadge,
  },
  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
    cameraName: {
      type: String,
      default: "",
    },
    selectedCamera: {
      type: Object as PropType<Camera>,
      default: () => ({}),
    },
    withLoadingAnimation: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    refreshTimeoutMinutes: {
      type: Number,
      default: 15,
    },
    refreshIntervalMs: {
      type: Number,
      default: 5000,
    },
    withRefresh: {
      type: Boolean,
      default: true,
    },
    projectExid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clip: { ...this.media },
      skeletonHeight: 0,
      MediaStatus,
      imageHeight: "220px",
    }
  },
  computed: {
    routeProjectExid(): string {
      const params = camelizeKeys(this.$route.params) as {
        projectExid?: string
      }

      return params.projectExid || ""
    },
    pendingClip(): Media | null {
      return this.isPending ? this.clip : null
    },
    thumbnailUrl(): string | undefined {
      if (this.isPendingTimelapse || this.clip.status === MediaStatus.Pending) {
        return "/evercam_logo.png"
      }

      return this.$imgproxy.get720pResizedImageUrl(this.clip?.thumbnailUrl)
    },
    showIntervalDates(): boolean {
      return [
        MediaType.Compare,
        MediaType.Timelapse,
        MediaType.Clip,
        MediaType.LocalClip,
      ].includes(this.clip.type)
    },
    isFailed(): boolean {
      return this.clip.status === MediaStatus.Failed
    },
    isPending(): boolean {
      return (
        [
          MediaStatus.Pending,
          MediaStatus.Creating,
          MediaStatus.Processing,
        ].includes(this.clip.status as MediaStatus) && this.withRefresh
      )
    },
    fileExtension(): string {
      return this.clip.fileName?.split(".").pop() || ""
    },
    isDocFile(): boolean {
      return [
        "pdf",
        "doc",
        "docx",
        "csv",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "txt",
      ].includes(this.fileExtension)
    },
    mediaFileIcon(): string {
      const fileIcons: Record<string, string> = {
        pdf: "fa-file-pdf",
        doc: "fa-file-word",
        docx: "fa-file-word",
        csv: "fa-file-csv",
        ppt: "fa-file-powerpoint",
      }
      const ext = this.fileExtension

      return fileIcons[ext] || "fa-file"
    },
    isPendingTimelapse(): boolean {
      return (
        this.clip.type === MediaType.Timelapse &&
        this.clip.status !== MediaStatus.Completed &&
        this.withRefresh
      )
    },
    isMediaClipClickable(): boolean {
      return this.clickable
    },
    mediaLink(): string | null {
      const mediaUrl = `/v2/projects/${this.routeProjectExid}/media-hub/${this.clip.exid}`

      return this.isPending ? null : mediaUrl
    },
    getMediaLink(): string | null | undefined {
      return this.isMediaClipClickable ? this.mediaLink : undefined
    },
    failedMediaPlaceholderText(): string {
      return `${this.$t("content.media_hub.create_clip_error")}${Object.keys(
        MediaType
      )
        .find((key) => MediaType[key] === this.clip.type)
        ?.replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()}`
    },
  },
  watch: {
    pendingClip: {
      immediate: true,
      async handler(pendingClip) {
        if (!pendingClip || !this.withRefresh) {
          return
        }
        const currentTime = new Date().getTime()
        let clipCreatedTime = new Date(pendingClip.createdAt).getTime()
        const timeDifferenceInMilliseconds = Math.abs(
          currentTime - clipCreatedTime
        )
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInMilliseconds / (1000 * 60)
        )

        if (timeDifferenceInMinutes < this.refreshTimeoutMinutes) {
          const pendingClipInterval = setInterval(
            () => this.getPendingMediaStatus(pendingClip, pendingClipInterval),
            this.refreshIntervalMs
          )
        }
      },
    },
  },
  methods: {
    getRequesterName(requesterName: string) {
      return requesterName
        ?.split(" ")
        ?.map((name) => name?.[0]?.toUpperCase())
        ?.join("")
    },
    getDate(clip: Media, date: DateType) {
      return this.$moment
        .tz(date, clip.cameraTimezone)
        .format("Do MMM, YYYY | LT")
    },

    async getPendingMediaStatus(item: Media, pendingMediaItemInterval: number) {
      let clip = null as Media | null
      try {
        clip = await EvercamApi.mediaHub.cShow(
          this.projectExid ? this.projectExid : this.routeProjectExid,
          item.exid as string
        )
        if (
          [MediaStatus.Completed, MediaStatus.Failed].includes(clip?.status)
        ) {
          this.clip = clip
          this.$emit("refresh-media-hub")
          clearInterval(pendingMediaItemInterval)
        }
      } catch (error) {
        console.error(error)
      }
    },

    handleImageError(e) {
      console.error("error loading image", e)
      e.target.src = "/unavailable.jpg"
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `lighten-${hover ? "2" : "1"}`
      }

      return `darken-${hover ? "2" : "1"}`
    },
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToPreviousPage)
    },
    onResize(entries: ResizeObserverEntry) {
      this.imageHeight = entries.contentRect.width / 2.46 + "px"
    },
  },
})
